exports.components = {
  "component---src-pages-404-js": () => import("./../../../src/pages/404.js" /* webpackChunkName: "component---src-pages-404-js" */),
  "component---src-pages-about-js": () => import("./../../../src/pages/about.js" /* webpackChunkName: "component---src-pages-about-js" */),
  "component---src-pages-artists-index-js": () => import("./../../../src/pages/artists/index.js" /* webpackChunkName: "component---src-pages-artists-index-js" */),
  "component---src-pages-artists-works-index-js": () => import("./../../../src/pages/artists/works/index.js" /* webpackChunkName: "component---src-pages-artists-works-index-js" */),
  "component---src-pages-index-js": () => import("./../../../src/pages/index.js" /* webpackChunkName: "component---src-pages-index-js" */),
  "component---src-pages-mediums-index-js": () => import("./../../../src/pages/mediums/index.js" /* webpackChunkName: "component---src-pages-mediums-index-js" */),
  "component---src-pages-project-types-index-js": () => import("./../../../src/pages/project-types/index.js" /* webpackChunkName: "component---src-pages-project-types-index-js" */),
  "component---src-pages-projects-index-js": () => import("./../../../src/pages/projects/index.js" /* webpackChunkName: "component---src-pages-projects-index-js" */),
  "component---src-pages-test-js": () => import("./../../../src/pages/test.js" /* webpackChunkName: "component---src-pages-test-js" */),
  "component---src-templates-artist-page-js": () => import("./../../../src/templates/artist-page.js" /* webpackChunkName: "component---src-templates-artist-page-js" */),
  "component---src-templates-medium-page-js": () => import("./../../../src/templates/medium-page.js" /* webpackChunkName: "component---src-templates-medium-page-js" */),
  "component---src-templates-project-page-js": () => import("./../../../src/templates/project-page.js" /* webpackChunkName: "component---src-templates-project-page-js" */),
  "component---src-templates-project-type-page-js": () => import("./../../../src/templates/projectType-page.js" /* webpackChunkName: "component---src-templates-project-type-page-js" */),
  "component---src-templates-work-page-js": () => import("./../../../src/templates/work-page.js" /* webpackChunkName: "component---src-templates-work-page-js" */)
}

